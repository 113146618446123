


import React, { useContext } from "react";
import { graphql, useStaticQuery } from "gatsby";


const DataContext = React.createContext();

export const useData = () => {
  return useContext(DataContext);
};



export const DataProvider = ({children}) => {

const data = useStaticQuery(graphql`
    {
      allWpPost {
        edges {
          node {
            title
            excerpt
            slug
            date
            id
            content
            featuredImage {
              node {
                sourceUrl
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
            categories {
              nodes {
                name
                slug
              }
            }
            tags {
              nodes {
                slug
                name
              }
            }
            author {
              node {
                name
              }
            }
          }
        }
      }
      allWpPage {
        edges {
          node {
               promotion {
                cta
                promotionText
                url
              }
          
            title
            content
            featuredImage {
              node {
                sourceUrl
              }
            }
          }
        }
      }
    }
  `);
//const anotherValue = "example"
const sortedPosts = data.allWpPost.edges.sort((a,b)=>a.node.date<b.node.date?1:-1)
  return (
    <DataContext.Provider value={{data,sortedPosts}}>
        {children}
    </DataContext.Provider>
  );
};
